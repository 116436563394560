// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useEffect, useState } from "react";

// mobx
import { observer } from "mobx-react";

import PricingList from "components/PricingList";

// fragments
import { fragments } from "services/get-fragments";

// semantic-ui
import { Segment, Container } from "semantic-ui-react";

import PaymentMessage from "components/PaymentMessage";
import { updateSubscriptions } from "services/api-catalog";

export const PricingPage = observer(() => {
  const [hasMsg, setHasMsg] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const hasMessage = urlParams.get("action");
    if (hasMessage) updateSubscriptions(true);

    setHasMsg(hasMessage);

    window.history.replaceState(null, "", window.location.href.split("?")[0]);
  }, []);

  return (
    <>
      <PaymentMessage hasMessage={hasMsg} />
      <Segment
        vertical
        style={{ padding: "40px 0", marginBottom: 75 }}
        className="home-segment"
      >
        {fragments.Pricing?.plans && (
          <Container textAlign="justified">
            <h2>{fragments.Pricing?.title}</h2>
            <PricingList />
          </Container>
        )}
      </Segment>
    </>
  );
});

export default PricingPage;
