// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { Image } from "semantic-ui-react";

// mobx
import { observer } from "mobx-react";

// fragments

// components

export const Footer = observer(
  class Footer extends React.Component {
    render() {
      return (
        <footer>
          <section>
            <div className="footer-image-wrapper">
              <div>
                <Image size="tiny" src="/custom-content/nav-logo.png" />
              </div>
              <hr />
            </div>
          </section>
          <section className="footer-main-content">
            <div className="footer-links-wrapper">
              <section>
                <div className="footer-heading">Contact us</div>
                <ul>
                  <li>
                    555 Hastings W St., #1200
                    <br />
                    Vancouver, BC, CA V6B 4N6
                  </li>
                  <li>
                    <a href="mailto:support@diabits.com">support@diabits.com</a>
                  </li>
                </ul>
              </section>
              <section>
                <div className="footer-heading">Products</div>
                <ul>
                  <li>
                    <a href="https://www.diabits.com/">Diabits App</a>
                  </li>
                  <li>
                    <a href="https://www.endobits.com/">Endobits</a>
                  </li>
                </ul>
              </section>
              <section>
                <div className="footer-heading">About</div>
                <ul>
                  <li>
                    <a href="https://www.bioconscious.tech/about">About Us</a>
                  </li>
                  <li>
                    <a href="https://www.bioconscious.tech/careers">Careers</a>
                  </li>
                  <li>
                    <a href="https://www.bioconscious.tech/research">
                      Research
                    </a>
                  </li>
                </ul>
              </section>
              <section>
                <div className="footer-heading">Terms</div>
                <ul>
                  <li>
                    <a href="http://bioconscious.tech/diabits-terms">
                      Terms of Service
                    </a>
                  </li>
                  <li>
                    <a href="http://bioconscious.tech/privacy">
                      Privacy Policy
                    </a>
                  </li>
                  <li>
                    <a href="https://www.bioconscious.tech/">Corporate</a>
                  </li>
                </ul>
              </section>
              <section className="footer-socials">
                <div className="footer-heading">Social</div>
                <ul>
                  <li>
                    <a href="https://www.facebook.com/DiabitsApp">
                      <img
                        alt="facebook"
                        src="/custom-content/social-images/facebook.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.youtube.com/channel/UCZISzpYNA3lOfaXN6TUyzSQ">
                      <img
                        alt="youtube"
                        src="/custom-content/social-images/youtube.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/diabitsapp/">
                      <img
                        alt="instagram"
                        src="/custom-content/social-images/instagram.png"
                      />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/company/18260453">
                      <img
                        alt="linkedin"
                        src="/custom-content/social-images/linkedin.png"
                      />
                    </a>
                  </li>
                </ul>
              </section>
            </div>
          </section>
        </footer>
      );
    }
  }
);

export default Footer;
