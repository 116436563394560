// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { useState, useEffect } from "react";

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

import { Line as LineChart } from "react-chartjs-2";

// react-router

// semantic-ui
import { Segment, Container, Dropdown } from "semantic-ui-react";

const scenariosJson = require("../scenarios.json");

const scenarioKeys = Object.keys(scenariosJson).sort(
  (a, b) => parseFloat(a) - parseFloat(b)
);

const styles = {
  Input: { color: "#141634", label: "Actual BGL (Past)" },
  Output: { color: "#F82147", label: "Diabits Prediction" },
  "Simple Linear": { color: "#37D4C7", label: "Linear Predicton" },
  Actual: { color: "#555472", label: "Actual BGL (Future)" },
};

export const DemoPage = observer(() => {
  const [currentScenario, setScenario] = useState();
  useEffect(() => {
    // animation won;t run straight away so need to delay
    setTimeout(() => setScenario(scenarioKeys[0]), 200);
  }, []);

  const splitDataPoints = {};
  if (currentScenario) {
    scenariosJson[currentScenario].forEach((entry) => {
      if (!splitDataPoints[entry.type])
        splitDataPoints[entry.type] = {
          label: styles[entry.type].label,
          fill: false,
          lineTension: 0.4,
          pointRadius: 1,
          backgroundColor: styles[entry.type].color,
          borderColor: styles[entry.type].color,
          borderWidth: 1,
          data: [],
        };
      splitDataPoints[entry.type].data.push({
        x: parseFloat(entry.t),
        y: entry.value,
      });
    });
  }

  return (
    <>
      <Segment vertical className="demo-segment">
        <Container fluid text textAlign="justified">
          <fragments.Demo.jsx />
        </Container>
        <Container fluid text style={{ margin: "20px 0" }}>
          <span>
            Here you can see how our algorithm performs in different scenarios:{" "}
          </span>
          <Dropdown
            value={currentScenario}
            onChange={(_event, { value }) => {
              setScenario(value);
            }}
            placeholder="Select Scenario"
            selection
            options={scenarioKeys.map((scenario, index) => ({
              key: index,
              value: scenario,
              text: "Scenario " + scenario,
            }))}
          />
        </Container>

        <Container>
          <LineChart
            data={{
              datasets: Object.values(splitDataPoints),
            }}
            options={{
              scales: {
                xAxes: [
                  {
                    type: "linear",
                    position: "bottom",
                  },
                ],
              },
            }}
          />
        </Container>
      </Segment>
    </>
  );
});

export default DemoPage;
