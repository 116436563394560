// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

// semantic-ui
import { Segment, Container } from "semantic-ui-react";

export const FeaturesPage = observer(() => (
  <>
    <Segment
      vertical
      style={{ padding: "40px 0", marginBottom: 75 }}
      className="home-segment"
    >
      <Container fluid text textAlign="justified">
        <fragments.Features.jsx />
      </Container>
    </Segment>
  </>
));

export default FeaturesPage;
