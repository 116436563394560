import React from "react";
import { Message } from "semantic-ui-react";

const PaymentMessage = (props) => {
  return (
    <>
      {props.hasMessage === "success" && (
        <Message
          positive
          header="Payment successful"
          content="Thank you for subscribing. Your API Key is now active for our API."
        />
      )}
      {props.hasMessage === "cancel" && (
        <Message
          negative
          header="Payment cancelled"
          content="You must have a valid subscription to use our API."
        />
      )}
    </>
  );
};

export default PaymentMessage;
