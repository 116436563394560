// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { Menu, Image } from "semantic-ui-react";

import {
  isAdmin,
  isAuthenticated,
  isRegistered,
  logout,
  getLoginRedirectUrl,
} from "services/self";

import { cognitoDomain, cognitoClientId } from "../services/api";

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

// components
import MenuLink from "components/MenuLink";
import { store } from "services/state";

function getCognitoUrl(type) {
  const redirectUri = getLoginRedirectUrl();
  return `${cognitoDomain}/${type}?response_type=token&client_id=${cognitoClientId}&redirect_uri=${redirectUri}`;
}

export const NavBar = observer(
  class NavBar extends React.Component {
    render() {
      const loggedIn = isAuthenticated();
      const email = store.user && store.user.email;
      return (
        <Menu
          inverted
          borderless
          attached
          style={{ flex: "0 0 auto" }}
          stackable
          className="nav-bar"
        >
          <MenuLink to="/">
            <Image size="tiny" src="/custom-content/nav-logo.png" />
          </MenuLink>
          <Menu.Menu
            className="mobile-only"
            onClick={(ev) => {
              ev.currentTarget.parentNode.classList.toggle("show-mobile-menu");
            }}
            position="right"
          >
            <div className="hamburger-container">
              <div className="hamburger-bar1"></div>
              <div className="hamburger-bar2"></div>
              <div className="hamburger-bar3"></div>
            </div>
          </Menu.Menu>
          <Menu.Menu className="top-menu-mobile-hidden">
            <Menu.Menu position="left" className="top-menu-left">
              <MenuLink to="/features">{fragments.Features.title}</MenuLink>
              <MenuLink to="/demo">{fragments.Demo.title}</MenuLink>
              <MenuLink to="/pricing">{fragments.Pricing.title}</MenuLink>
              <MenuLink to="/getting-started">
                {fragments.GettingStarted.title}
              </MenuLink>
              {loggedIn && (
                <MenuLink to="/apis">{fragments.APIs.title}</MenuLink>
              )}
            </Menu.Menu>

            <Menu.Menu position="right" className="top-menu-right">
              {loggedIn ? (
                <>
                  {isAdmin() && (
                    <MenuLink to="/admin/apis">Admin Panel</MenuLink>
                  )}
                  {isRegistered() && (
                    <MenuLink to="/dashboard">My Dashboard</MenuLink>
                  )}
                  <MenuLink onClick={logout}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        textAlign: "center",
                      }}
                    >
                      {email && (
                        <span style={{ marginBottom: ".5rem" }}>{email}</span>
                      )}
                      <span>Sign out</span>
                    </div>
                  </MenuLink>
                </>
              ) : (
                <>
                  <MenuLink to={getCognitoUrl("login")}>Sign In</MenuLink>
                  <MenuLink id="register-link" to={getCognitoUrl("signup")}>
                    Register
                  </MenuLink>
                </>
              )}
            </Menu.Menu>
          </Menu.Menu>
        </Menu>
      );
    }
  }
);

export default NavBar;
