// Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";
import { Image } from "semantic-ui-react";

// mobx
import { observer } from "mobx-react";

// fragments
import { fragments } from "services/get-fragments";

import { Card, Button } from "semantic-ui-react";
import { subscribe } from "services/api-catalog";
import { isRegistered } from "services/self";

const PricingList = observer(() => {
  const isReg = isRegistered();
  return (
    <Card.Group
      className="pricing-items"
      itemsPerRow={window.innerWidth < 600 ? 1 : 3}
    >
      {fragments.Pricing.plans.map(
        ({ title, cost, description, configKey }, indexPlans) => (
          <Card verticalAlign="middle" key={`plan-${indexPlans}`}>
            <Card.Content>
              <Card.Header as="a">{title}</Card.Header>
              <Button
                positive
                onClick={() => {
                  if (isReg) subscribe(window.config.stripePriceIds[configKey]);
                  else document.getElementById("register-link").click();
                }}
              >
                {isReg ? "Subscribe" : "Register"}
              </Button>
              <Card.Meta className="pricing-cost">{cost}</Card.Meta>
              <Card.Description>
                <ul style={{ textAlign: "left" }}>
                  {description.map((desc, indexdesc) => (
                    <li key={`descr-${indexdesc}`}>{desc}</li>
                  ))}
                </ul>
              </Card.Description>
            </Card.Content>
          </Card>
        )
      )}
      <Card verticalAlign="middle">
        <Card.Content>
          <Card.Header as="a">Custom</Card.Header>
          <a
            className="ui positive button"
            href="mailto:ask@diabits.com?subject=Custom API Pricing"
          >
            Contact us
          </a>
          <Card.Meta className="pricing-cost">Custom</Card.Meta>
          <Card.Description>
            Get in touch if you require a custom package. We are more than happy
            to accomdate your needs
          </Card.Description>
        </Card.Content>
      </Card>
    </Card.Group>
  );
});

export default PricingList;
